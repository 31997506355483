export default class Singleton {
	constructor(childClass) {
		if (childClass._instance) {
			return childClass._instance
		} else {
			childClass._instance = this
			return this
		}
	}
}
