import Singleton from './Singleton'
import { TUBE } from './config'
import TubeBuilder from './TubeBuilder'

export default class UnionTube extends Singleton {
	constructor() {
		super(UnionTube)
		if (!this.tubes) {
			this.tubes = {}
		}
	}

	get(person, spouse) {
		return this.tubes[person.id][spouse.id] || this.tubes[spouse.id][person.id] || null
	}

	create(person, spouse) {
		const length = spouse.leftX - person.rightX
		const tube = new TubeBuilder().build(TUBE.horizontal, length)
		const x = person.rightX + length / 2
		tube.position.set(
			x,
			person.y + ((person.spouses.length - 1) * TUBE.gap) / 2,
			person.z
		)

		 //Moving all the tubes negative Y to center them on the person box
		if (this.tubes[person.id]) {
			for (const [id, tube] of Object.entries(this.tubes[person.id])) {
				tube.position.y -= TUBE.gap / 2
			}
		}
		for (const spouse of person.spouses) {
			const childrenGroup = person.childrenBySpouse[spouse.id] && person.childrenBySpouse[spouse.id][0].siblingsGroup
			if (childrenGroup) {
				childrenGroup.position.y -= TUBE.gap / 2
			}
		}

		if (!this.tubes[person.id]) {
			this.tubes[person.id] = {}
		}
		if (!this.tubes[spouse.id]) {
			this.tubes[spouse.id] = {}
		}
		this.tubes[person.id][spouse.id] = tube
		this.tubes[spouse.id][person.id] = tube

		return tube
	}

	empty() {
		this.tubes = {}
	}
}
