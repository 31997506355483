import { Vector3 } from "three";
import Singleton from "./Singleton";
import Person from "./Person";
import CollisionChecker from "./CollisionChecker";
import { MODE } from "./config";

export default class Tree extends Singleton {
	constructor() {
		super(Tree)
		if (!this.init) {
			this.init = true
			this.currentPerson = null
			this.boxPosition = new Vector3()
			this.json = null
			this.info = {
				title: ''
			}
			this.mode = null
		}
	}

	build(json, mode) {
		this.mode = mode
		this.json = json
		const firstPersonId = json[0].id
		return this.focusOn(firstPersonId)
	}

	getNode(id) {
		return id && this.json.find(record => record.id === id)
	}

	getName(id) {
		const record = this.json.find(record => record.id === id)
		return record && record.data.fn ? record.data.fn : "???"
	}

	focusOn(id) {
		const node = this.getNode(id)
		this.currentPerson = new Person(node)
		const tree = this.currentPerson.build(this.boxPosition, this.mode)
		this.buildAscendants(this.currentPerson, node)
		this.buildDescendants(this.currentPerson, node)
		new CollisionChecker().checkCollision(this.currentPerson)

		this.info.title = node.data.fn + ' ' + node.data.ln

		return tree
	}

	buildAscendants(person, node) {
		const fatherNode = node && this.getNode(node.rels.father)
		const motherNode = node && this.getNode(node.rels.mother)
		if (fatherNode || motherNode) {
			const [father, mother] = person.buildParents(
				fatherNode,
				motherNode)
			this.buildAscendants(father, fatherNode)
			this.buildAscendants(mother, motherNode)
		}
	}

	buildDescendants(person, node) {
		if (node.rels.spouses && node.rels.spouses.length > 0) {
			for (const spouseId of node.rels.spouses) {
				const spouse = person.buildSpouse(this.getNode(spouseId))
				if (node.rels.children && node.rels.children.length > 0) {
					const children = []
					for (const childId of node.rels.children) {
						const childNode = this.getNode(childId)
						// collecting all the children with the same parents
						if (childNode && (childNode.rels.mother === spouseId || childNode.rels.father === spouseId)) {
							children.push(childNode)
						}
					}
					if (children.length > 0) {
						person.buildChildrenBySpouse(children, spouse)
						for (const child of person.childrenBySpouse[spouseId]) {
							this.buildDescendants(child, this.getNode(child.id))
						}
					}
				}
			}
		}
	}
}
