import { CylinderGeometry, Mesh } from 'three'
import { TUBE } from './config'
import Singleton from './Singleton'

export default class TubeBuilder extends Singleton {
	constructor() {
		super(TubeBuilder)
	}

	buildGeometry(length) {
		return new CylinderGeometry(TUBE.diameter, TUBE.diameter, length, 15)
	}

	build(orientation, length) {
		let geometry
		switch (length) {
			case TUBE.unionLength.boxMode:
				geometry = TUBE.unionGeo.boxMode
				break
			case TUBE.unionLength.circleMode:
				geometry = TUBE.unionGeo.circleMode
				break
			case TUBE.descendantLength.boxMode:
				geometry = TUBE.descendantGeo.boxMode
				break
			case TUBE.descendantLength.circleMode:
				geometry = TUBE.descendantGeo.circleMode
				break
			case TUBE.childrenLength:
				geometry = TUBE.childrenGeo
				break
			default:
				geometry = this.buildGeometry(length)
				break
		}
		const tube = new Mesh(geometry, TUBE.material)
		if (orientation == TUBE.horizontal) {
			tube.rotation.set(0, 0, Math.PI / 2)
		}
		return tube
	}

	extend(tube, length) {
		tube.geometry.dispose()
		tube.geometry = this.buildGeometry(length)
	}
}
