import { Controller } from "@hotwired/stimulus"
import * as THREE from 'three'
import { CSS2DRenderer } from 'three/addons/renderers/CSS2DRenderer.js'
import gsap from 'gsap'
import Boxes from '../togetree/Boxes.js'
import { ANIM, AVATAR, BOX, CIRCLE, COLORS, MODE, TUBE, WINDOW } from '../togetree/config.js'
import UnionTube from '../togetree/UnionTube.js'
import People from '../togetree/People.js'
import Tree from '../togetree/Tree.js'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

// Connects to data-controller="togetree"
export default class extends Controller {
    static values = { 
        data: Array, 
        avatar: String 
    }

    connect() {
        this.initialize()
        this.setupScene()
        this.setupEventListeners()
        this.initializeTree()

        // GO! ===================
		this.scene.add(this.tree.build(this.dataValue, this.mode))
		this.title.innerText = this.tree.info.title
		this.updateCamera(false)

		this.loop()
    }

    disconnect() {
        this.removeEventListeners()
        this.destroy()
    }

    initialize() {
        this.handleResize = this.handleResize.bind(this)
        this.handleTabClick = this.handleTabClick.bind(this)
        this.recordTab = document.querySelector('#record-tab')
        this.bioTab = document.querySelector('#bio-tab')
        this.canvas = document.querySelector('canvas#webgl')
        
        this.sizes = {
            width: this.canvas.offsetWidth,
            height: this.canvas.offsetHeight,
        }
        
        this.zoom = 100
        this.currentHover = null
        this.textRenderer = null
    }

    setupScene() {
        this.scene = new THREE.Scene()
        this.setupCamera()
		this.scene.add(this.camera)
        this.initTextRenderer()

        this.controls = new OrbitControls(this.camera, this.canvas)
        this.controls.enableDamping = false
        this.controls.dampingFactor = 0.3
        this.controls.enableRotate = false
        this.controls.maxZoom = 1
        this.controls.minZoom = 0.2
        //this.controls.maxTargetRadius = 8
        this.controls.mouseButtons = {
            LEFT: THREE.MOUSE.PAN
        }
        this.controls.touches = {
            ONE: THREE.TOUCH.PAN,
            TWO: THREE.TOUCH.DOLLY_PAN
        }

        const light = new THREE.AmbientLight(0xffffff, 1.2)
        this.scene.add(light)
        const light2 = new THREE.DirectionalLight(0xffffff, 2)
        light2.position.y = -1
        light2.position.z = 1
        this.scene.add(light2)

        this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true })
        this.renderer.setSize(this.sizes.width, this.sizes.height, false)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        // this.renderer.setClearColor(COLORS.background)
        this.renderer.setClearColor(0x000000, 0); // transparent background

        this.title = document.querySelector("#person-info")

        this.mouse = new THREE.Vector2()
        this.raycaster = new THREE.Raycaster()
    }

    setupCamera() {
        this.camera = new THREE.OrthographicCamera(this.sizes.width / -this.zoom,
            this.sizes.width / this.zoom,
            this.sizes.height / this.zoom,
            this.sizes.height / -this.zoom,
            -100,
            100)
        this.camera.position.set(0, 0, 6)
    }

    setupEventListeners() {
        window.addEventListener('resize', this.handleResize)

        window.addEventListener('pointermove', this.updateMouseCoords.bind(this))
        window.addEventListener('pointerdown', this.updateMouseCoords.bind(this))
        this.handlePointerUp = this.handlePointerUp.bind(this)
        window.addEventListener('pointerup', this.handlePointerUp)

        this.recordTab.addEventListener('click', () => this.handleTabClick('record'))
        this.bioTab.addEventListener('click', () => this.handleTabClick('bio'))
    }

    removeEventListeners() {
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('pointerup', this.handlePointerUp)
        window.removeEventListener('pointermove', this.updateMouseCoords.bind(this))
        window.removeEventListener('pointerdown', this.updateMouseCoords.bind(this))

        this.recordTab.removeEventListener('click', () => this.handleTabClick('record'))
        this.bioTab.removeEventListener('click', () => this.handleTabClick('bio'))
    }


    handleResize() {
        const isMobile = this.canvas.offsetWidth < 640
        
        if (isMobile && this.mode === MODE.boxMode) {
            this.setMode(MODE.circleMode)
            // console.log("  setMode(MODE.circleMode)")
            this.newFocus(this.tree.json[0].id)
        }
        if (!isMobile && this.mode === MODE.circleMode) {
            this.setMode(MODE.boxMode)
            // console.log("  setMode(MODE.boxMode)")
            this.newFocus(this.tree.json[0].id)
        }

        this.updateSizes()
        this.updateCameraProjection()
        this.updateRenderers()
        this.updateCamera()
    }

    handlePointerUp() {
        // console.log("  handlePointerUp()")
        if (this.currentHover) {
            const box = this.currentHover.object.parent
            const index = new Boxes().items.indexOf(box)
            const clickedPerson = new People().items[index]
            if (clickedPerson.id !== this.tree.currentPerson.id) {
                this.tree.boxPosition.x = clickedPerson.x
                this.tree.boxPosition.y = clickedPerson.y
                this.tree.boxPosition.z = clickedPerson.z
                this.nextTree(clickedPerson.link)
            }
        }
    }

    updateSizes() {
        this.sizes.width = this.canvas.offsetWidth
        this.sizes.height = this.canvas.offsetHeight
    }

    updateCameraProjection() {
        this.camera.left = this.sizes.width / -this.zoom
        this.camera.right = this.sizes.width / this.zoom
        this.camera.top = this.sizes.height / this.zoom
        this.camera.bottom = this.sizes.height / -this.zoom
        this.camera.updateProjectionMatrix()
    }

    handleTabClick(type) {
        const personId = this.tree.currentPerson.id
        const tab = type === 'record' ? this.recordTab : this.bioTab
        tab.href = `../${personId}#${type}`
    }

    initializeTree() {
        AVATAR.url = this.avatarValue
        this.tree = new Tree()
        this.setInitialMode()
    }

    setInitialMode() {
        const mode = window.innerWidth < 640 ? MODE.circleMode : MODE.boxMode
        this.setMode(mode)
        // console.log(`MODE : ${mode === MODE.circleMode ? 'CIRCLE' : 'BOX'}`)
    }

	setMode(mode) {
		this.mode = mode
		this.tree.mode = mode
	}

    updateMouseCoords(event) {
		this.mouse.x = ((event.clientX - this.canvas.getBoundingClientRect().left) / this.sizes.width - 0.5) * 2
		this.mouse.y = ((event.clientY - this.canvas.getBoundingClientRect().top) / this.sizes.height - 0.5) * -2
	}

    updateCamera(animation) {
		const sceneBounding = this.getSceneBoudingBox()
		const target = new THREE.Vector3(sceneBounding.center.x, sceneBounding.center.y, 6)
		let zoomTarget = Math.min(
			Math.abs(this.camera.left / sceneBounding.leftToCenter),
			Math.abs(this.camera.bottom / sceneBounding.bottomToCenter)
		)
		if (zoomTarget > 1) {
			zoomTarget = 1
		}
		if (!animation) {
			this.controls.target = target
			this.camera.position.set(target.x, target.y, target.z)
			this.camera.zoom = zoomTarget
			this.camera.updateProjectionMatrix()
		} else {
			gsap.to(this.camera.position, {
				duration: ANIM.transition,
				x: target.x,
				y: target.y,
				ease: ANIM.ease
			})
			gsap.to(this.controls.target, {
				duration: ANIM.transition,
				x: target.x,
				y: target.y,
				ease: ANIM.ease
			})
			gsap.to(this.camera, {
				duration: ANIM.transition,
				zoom: zoomTarget,
				ease: ANIM.ease
			})
		}
	}

    initTextRenderer() {
        // console.log("  initTextRenderer()")
        if (this.textRenderer != null && document.body.contains(this.textRenderer.domElement)) {
            document.body.removeChild(this.textRenderer.domElement)
        }
		this.textRenderer = new CSS2DRenderer()
		this.textRenderer.setSize(this.sizes.width, this.sizes.height, false)
		this.textRenderer.domElement.style.position = 'absolute'
		this.textRenderer.domElement.style.top = `${this.canvas.getBoundingClientRect().top}px`
		this.textRenderer.domElement.style.left = `${this.canvas.getBoundingClientRect().left}px`
		this.textRenderer.domElement.style.pointerEvents = 'none'
		document.body.appendChild(this.textRenderer.domElement)
	}

    updateRenderers() {
        // console.log("  updateRenderers()")
        this.renderer.setSize(this.sizes.width, this.sizes.height, false)
        this.textRenderer.domElement.style.top = `${this.canvas.getBoundingClientRect().top}px`
        this.textRenderer.domElement.style.left = `${this.canvas.getBoundingClientRect().left}px`
        this.textRenderer.setSize(this.sizes.width, this.sizes.height, false)
    }

	newFocus(id, animation) {
        // console.log("  newFocus()")
        this.scene.remove(this.tree.currentPerson.group)
        new Boxes().empty()
        new UnionTube().empty()
        new People().empty()
        this.initTextRenderer()
		this.scene.add(this.tree.focusOn(id))
		this.title.innerText = this.tree.info.title
		this.updateCamera(animation)
		gsap.to(this.tree.currentPerson.group.position, {
			duration: ANIM.transition,
			x: 0,
			y: 0,
			ease: ANIM.ease
		})
	}

    async nextTree(nextTreeUrl) {
		let response = await fetch(nextTreeUrl)
		let json = await response.json()
		this.tree.json = json
		this.newFocus(json[0].id, true)
	}

    getSceneBoudingBox() {
        const horizPadding = WINDOW.horizPadding[this.mode]
        const vertPadding = WINDOW.vertPadding[this.mode]
        let minX = new People().items[0].leftX - horizPadding
        let maxX = new People().items[0].rightX + horizPadding
        let minY = new People().items[0].y - vertPadding
        let maxY = new People().items[0].y + vertPadding
        for (const person of new People().items) {
            if (person.leftX - horizPadding < minX) {
                minX = person.leftX - horizPadding
            }
            if (person.rightX > maxX) {
                maxX = person.rightX + horizPadding
            }
            if (person.y - vertPadding < minY) {
                minY = person.y - vertPadding
            }
            if (person.y + vertPadding > maxY) {
                maxY = person.y + vertPadding
            }
        }
        const width = maxX - minX
        const height = maxY - minY
        const centerX = width / 2 + minX
        const centerY = height / 2 + minY
        return {
            center: { x: centerX, y: centerY },
            leftToCenter: width / 2,
            bottomToCenter: height / 2
        }
    }

    loop() {
        this.raycaster.setFromCamera(this.mouse, this.camera)
        const hovers = this.raycaster.intersectObjects(new Boxes().items)
        for (const box of new Boxes().items) {
            if (box.children[0]) {
                box.children[0].material.color.set(COLORS.box)
            }
        }
        for (const box of hovers) {
            box.object.material.color.set(COLORS.boxHover)
        }
        if (hovers.length) {
            if (this.currentHover === null) {
                document.body.style.cursor = 'pointer'
            }
            this.currentHover = hovers[0]
        } else {
            if (this.currentHover) {
                document.body.style.cursor = 'default'
            }
            this.currentHover = null
        }
        if (this.tree.currentPerson && this.tree.currentPerson.box.children[0]) {
            this.tree.currentPerson.box.children[0].material.color.set(COLORS.boxSelected)
        }

        for (const person of new People().items) {
            person.infoDiv.style.transform = `scale(${this.camera.zoom})`
        }

        this.controls.update()
        this.camera.updateProjectionMatrix()
        this.renderer.render(this.scene, this.camera)
        this.textRenderer.render(this.scene, this.camera)
        this.tick = window.requestAnimationFrame(this.loop.bind(this))
    }

    destroy() {
        // console.log("  destroy()")
        // Cancel animation frame
        if (this.tick) {
            window.cancelAnimationFrame(this.tick)
            this.tick = null
        }

        // Remove DOM elements
        if (this.textRenderer != null && document.body.contains(this.textRenderer.domElement)) {
            document.body.removeChild(this.textRenderer.domElement)
        }

        // Dispose Three.js resources
        if (this.renderer) {
            this.renderer.dispose()
            this.scene.traverse((object) => {
                if (object.geometry) object.geometry.dispose()
                if (object.material) object.material.dispose()
            })
        }

        // Clean up controls
        if (this.controls) {
            this.controls.dispose()
        }

        // Reset cursor
        document.body.style.cursor = 'default'

        // Clear scene
        if (this.scene) {
            this.scene.clear()
        }

        // Clear references
        this.renderer = null
        this.scene = null
        this.camera = null
        this.controls = null
        this.textRenderer = null
        this.currentHover = null
    }
    
}
