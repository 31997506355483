import * as THREE from 'three'
import { CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js'
import { BOX, COLORS, BORDER, TUBE, AVATAR, CIRCLE, MODE } from './config'
import UnionTube from './UnionTube'
import TubeBuilder from './TubeBuilder'
import Boxes from './Boxes'
import People from './People'

export default class Person {
	constructor(node) {
		this.id = node.id
		this.firstName = node.data.fn
		this.lastName = node.data.ln
		this.lifeTime = node.data.lifetime
		this.occupation = node.data.desc
		this.avatar = node.data.avatar
		this.link = node.data.link
		this.group = null
		this.spouses = []
		this.childrenBySpouse = {}
		this.child = null
		this.siblings = []
		this.siblingsGroup = null
		this.children = []
		this.father = null
		this.mother = null
		this.parents = []
		this.hasParents = false
		this.isAscendant = false
		this.isDescendant = false
		this.box = null
		this.material = null
		this.infoDiv = null
		this.infoBox = null
		this.bound = 'left'
		this.mode = null

		new People().add(this)
	}

	build(position, mode) {
		if (mode) {
			this.mode = mode
		}
		this.material = new THREE.MeshStandardMaterial({ color: COLORS.box })
		this.box = new THREE.Group()
		this.group = new THREE.Group()
		this.group.add(this.box)
		this.infoBox = new THREE.Group()
		this.infoBox.position.y = 0

		if (position) {
			this.box.position.x = position.x
			this.box.position.y = position.y
			this.box.position.z = position.z
		}

		this.infoDiv = document.createElement('div')

		if (this.mode === MODE.circleMode) {

			const circle = new THREE.Mesh(CIRCLE.geometry, this.material)
			circle.rotateX(Math.PI / 2)
			this.box.add(circle)

			//const nameBox = new THREE.Mesh(CIRCLE.nameBox.geometry, this.material)
			//nameBox.position.y = -CIRCLE.width / 2
			//this.box.add(nameBox)

			let avatarUrl
			let opacity
			if (this.avatar) {
				avatarUrl = this.avatar
				opacity = 100
			} else {
				avatarUrl = AVATAR.url
				opacity = 10
			}
			this.infoDiv.classList.add("flex", "flex-col", "items-center", "gap-3", "text-[0.7rem]", "leading-4")
			this.infoDiv.insertAdjacentHTML('beforeend', `<img src="${avatarUrl}" class="w-[70px] h-[70px] opacity-${opacity} rounded-full border-2 border-zinc-300 object-cover">`)
			const infoWrap = document.createElement('div')
			infoWrap.classList.add('flex', 'flex-col', 'items-center')
			this.infoDiv.appendChild(infoWrap)
			infoWrap.insertAdjacentHTML('beforeend', `<p>${this.firstName}</p>`)
			infoWrap.insertAdjacentHTML('beforeend', `<p>${this.lastName}</p>`)
			if (this.lifeTime !== ' - ') {
				infoWrap.insertAdjacentHTML('beforeend', `<p>${this.lifeTime}</p>`)
			} else {
				infoWrap.insertAdjacentHTML('beforeend', `<p>&nbsp;</p>`)
			}
			this.infoBox.position.y -= 0.60

		} else if (this.mode === MODE.boxMode) {

			this.box.add(new THREE.Mesh(BOX.geometry, this.material))
			const borderRight = new THREE.Mesh(BORDER.geometry, this.material)
			borderRight.rotateX(Math.PI / 2)
			borderRight.position.x = BOX.width.boxMode / 2
			const borderLeft = new THREE.Mesh(BORDER.geometry, this.material)
			borderLeft.rotateX(Math.PI / 2)
			borderLeft.rotateY(Math.PI)
			borderLeft.position.x = - BOX.width.boxMode / 2
			this.box.add(borderLeft, borderRight)

			this.infoDiv.classList.add("flex", "items-center", "gap-2", "text-xs", "text-white", "w-[220px]")
			let avatarUrl
			let opacity
			if (this.avatar) {
				avatarUrl = this.avatar
				opacity = 100
			} else {
				avatarUrl = AVATAR.url
				opacity = 10
			}
			this.infoDiv.insertAdjacentHTML('beforeend', `<img src="${avatarUrl}" class="w-[80px] h-[80px] opacity-${opacity} rounded-full border-2 border-zinc-300 object-cover">`)
			const infoWrap = document.createElement('div')
			this.infoDiv.appendChild(infoWrap)
			infoWrap.insertAdjacentHTML('beforeend', `<p>${this.firstName}</p>`)
			infoWrap.insertAdjacentHTML('beforeend', `<p>${this.lastName}</p>`)
			if (this.occupation) {
				infoWrap.insertAdjacentHTML('beforeend', `<p>${this.occupation}</p>`)
			}
			if (this.lifeTime !== ' - ') {
				infoWrap.insertAdjacentHTML('beforeend', `<p>${this.lifeTime}</p>`)
			} else {
				infoWrap.insertAdjacentHTML('beforeend', `<p>&nbsp;</p>`)
			}
		}

		const divWrap = document.createElement('div')
		divWrap.appendChild(this.infoDiv)
		const text = new CSS2DObject(divWrap)
		this.infoBox.add(text)
		this.box.add(this.infoBox)

		new Boxes().items.push(this.box)

		return this.group
	}

	get x() {
		return this.box.getWorldPosition(new THREE.Vector3()).x
	}

	get y() {
		return this.box.getWorldPosition(new THREE.Vector3()).y
	}

	get z() {
		return this.box.getWorldPosition(new THREE.Vector3()).z
	}

	get leftX() {
		return this.x - BOX.width[this.mode] / 2
	}

	get rightX() {
		return this.x + BOX.width[this.mode] / 2
	}

	get boundingBox() {
		const boundingBox = new THREE.Box3(new THREE.Vector3(), new THREE.Vector3())
		//this.box.localToWorld(new THREE.Vector3())
		this.box.updateWorldMatrix(true, true)
		boundingBox.setFromObject(this.box)
		return boundingBox
	}

	get descendantPersonToMove() {
		let generation = 0
		let person = this
		while (person.child && person.bound === this.bound) {
			person = person.child
			generation += 1
		}
		return [person, generation]
	}

	ascendantMove() {
		const unionTube = new UnionTube().get(this, this.spouses[0])
		let tubeLength
		if (this.bound === 'left') {
			this.group.position.x -= BOX.overflow[this.mode]
			//console.log(`Moving ${this.firstName} to the left`)
			this.spouses[0].group.position.x += BOX.overflow[this.mode] * 2
			//console.log(`Moving ${this.spouses[0].firstName} to the right`)
			tubeLength = this.spouses[0].leftX - this.rightX
			unionTube.position.x += BOX.overflow[this.mode]
		} else {
			this.group.position.x += BOX.overflow[this.mode]
			//console.log(`Moving ${this.firstName} to the right`)
			this.spouses[0].group.position.x -= BOX.overflow[this.mode] * 2
			//console.log(`Moving ${this.spouses[0].firstName} to the left`)
			tubeLength = this.leftX - this.spouses[0].rightX
			unionTube.position.x -= BOX.overflow[this.mode]
		}
		new TubeBuilder().extend(unionTube, tubeLength)
	}

	descendantMove() {
		const index = this.siblings.indexOf(this)
		for (let i = 0; i < index + 1; i++) {
			this.siblings[i].group.position.x -= BOX.overflow[this.mode]
		}
		const horizTube = this.siblings.group.children[0]
		const length = this.siblings[this.siblings.length - 1].x - this.siblings[0].x
		new TubeBuilder().extend(horizTube, length)
		for (const mesh of this.siblings.group.children) {
			if (mesh !== horizTube) {
				mesh.position.x += BOX.overflow[this.mode] / 2
			}
		}
	}

	doubleSpouseMove() {
		this.moveSpouseAndChildrenToTheRight(this.mother)
		const index = this.father.spouses.indexOf(this.mother)
		for (const spouse of this.father.spouses.slice(index + 1)) {
			this.moveSpouseAndChildrenToTheRight(spouse)
		}
	}

	moveSpouseAndChildrenToTheRight(spouse) {
		spouse.group.position.x += BOX.overflow[this.mode]
		const ut = new UnionTube().tubes[spouse.spouses[0].id][spouse.id]
		const length = spouse.leftX - spouse.spouses[0].rightX
		new TubeBuilder().extend(ut, length)
		ut.position.x += BOX.overflow[this.mode] / 2
		const childrenGroup = spouse.childrenBySpouse[spouse.spouses[0].id][0].siblingsGroup
		if (childrenGroup) {
			childrenGroup.position.x += BOX.overflow[this.mode]
		}
	}

	buildSpouse(node) {
		const spouse = new Person(node)
		this.bound = 'left'
		spouse.bound = 'right'
		let x
		const lastSpouse = this.spouses[this.spouses.length - 1]
		if (lastSpouse) {
			x = lastSpouse.x + BOX.width[this.mode] + BOX.gap[this.mode]
		} else {
			x = this.x + BOX.width[this.mode] + TUBE.unionLength[this.mode]
		}
		const position = new THREE.Vector3(x, this.y, this.z)
		this.group.add(spouse.build(position, this.mode))
		this.spouses.push(spouse)
		if (this.siblings.length) {
			spouse.isDescendant = true
		}
		spouse.spouses.push(this)

		const tube = new UnionTube().create(this, spouse)
		this.group.add(tube)
		return spouse
	}

	buildChildrenBySpouse(childrenBySpouse, spouse) {
		if (!this.childrenBySpouse[spouse.id]) {
			this.childrenBySpouse[spouse.id] = []
		}
		if (!spouse.childrenBySpouse[this.id]) {
			spouse.childrenBySpouse[this.id] = []
		}
		const unionTube = new UnionTube().tubes[this.id][spouse.id]
		let initX
		// the position of the descendants is not the same wether it's the first spouse or not
		if (this.spouses.length === 1) {
			initX = unionTube.position.x
		} else {
			initX = spouse.leftX - BOX.gap[this.mode] / 2
		}
		let parentsToChildrenLength
		if (childrenBySpouse.length === 1) {
			parentsToChildrenLength = TUBE.descendantLength[this.mode]
		} else {
			parentsToChildrenLength = TUBE.descendantLength[this.mode] - TUBE.childLength
		}
		const parentToChildrenTube = new TubeBuilder().build(TUBE.vertical, parentsToChildrenLength)
		parentToChildrenTube.position.set(
			initX,
			unionTube.position.y - parentsToChildrenLength / 2,
			this.z)
		//this.group.add(desTube)
		const childrenGroup = new THREE.Group()
		this.group.add(childrenGroup)
		childrenGroup.add(parentToChildrenTube)

		let horizTube
		let xMin
		let xMax
		if (childrenBySpouse.length > 1) {
			const tubeLength = (childrenBySpouse.length - 1) * (BOX.width[this.mode] + BOX.gap[this.mode])
			horizTube = new TubeBuilder().build(TUBE.horizontal, tubeLength)
			horizTube.position.set(
				initX,
				unionTube.position.y - parentsToChildrenLength,
				this.z)
			childrenGroup.add(horizTube)
			xMin = initX - tubeLength / 2
			xMax = initX + tubeLength / 2
		}

		childrenBySpouse.forEach((childNode, index) => {
			const child = new Person(childNode)
			let x
			let y
			let childTube
			if (childrenBySpouse.length === 1) {
				x = initX
				y = parentToChildrenTube.position.y - parentsToChildrenLength / 2 - BOX.height / 2
			} else {
				x = xMin + ((childrenBySpouse.indexOf(childNode)
					* (xMax - xMin)) / (childrenBySpouse.length - 1))
				childTube = new TubeBuilder().build(TUBE.vertical, TUBE.childLength)
				childTube.position.set(
					x,
					horizTube.position.y - TUBE.childLength / 2,
					unionTube.position.z)
				y = childTube.position.y - TUBE.childLength / 2 - BOX.height / 2
			}
			const position = new THREE.Vector3(x, y, this.z)
			childrenGroup.add(child.build(position, this.mode))
			if (childTube) {
				child.group.add(childTube)
			}
			child.isDescendant = true
			this.childrenBySpouse[spouse.id].push(child)
			spouse.childrenBySpouse[this.id].push(child)
			child.siblings = this.childrenBySpouse[spouse.id]
			child.siblingsGroup = childrenGroup
			child.father = this
			child.mother = spouse
			child.parents.push(this)
			child.parents.push(spouse)
			if (index === childrenBySpouse.length - 1) {
				child.bound = 'right'
			}
		})
		return this.childrenBySpouse[spouse.id]
	}

	buildParents(fatherNode, motherNode) {
		const desTube = new TubeBuilder().build(TUBE.vertical, TUBE.descendantLength[this.mode])
		desTube.position.set(
			this.box.position.x,
			this.box.position.y - BOX.height / 2 + BOX.height + TUBE.descendantLength[this.mode] / 2,
			this.box.position.z)
		this.group.add(desTube)

		const father = new Person(fatherNode)
		const position = new THREE.Vector3(
			this.box.position.x - BOX.width[this.mode] / 2 - TUBE.unionLength[this.mode] / 2,
			desTube.position.y + TUBE.descendantLength[this.mode] / 2,
			this.box.position.z)
		this.group.add(father.build(position, this.mode))
		const mother = father.buildSpouse(motherNode)
		mother.bound = 'right'

		this.hasParents = true
		father.child = this
		mother.child = this
		father.isAscendant = true
		mother.isAscendant = true
		return [father, mother]
	}
}
