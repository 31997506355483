import { BoxGeometry, CylinderGeometry, MeshStandardMaterial } from "three"

const MODE = {
	boxMode: 'boxMode',
	circleMode: 'circleMode'
}

const COLORS = {
	background: '#fef8ec',
	box: '#400269',
	boxHover: '#871ad6',
	boxSelected: '#871ad6',
	tube: '#aaaaaa',
	text: {
		boxMode: '',
		circleMode: '#28524D'
	}
}

const BOX = {
	width: {
		boxMode: 2.8,
		circleMode: 1.6
	},
	height: 1.7,
	depth: 1,
	gap: {
		boxMode: 2.2,
		circleMode: 1
	},
	overflow: {
		boxMode: 0,
		circleMode: 1.15
	},
	geometry: {},
}
BOX.geometry = new BoxGeometry(BOX.width.boxMode, BOX.height, BOX.depth)
BOX.overflow.boxMode = BOX.width.boxMode / 2 + BOX.gap.boxMode / 2 + 0.3

const BORDER = {
	geometry: new CylinderGeometry(BOX.height / 2, BOX.height / 2, BOX.depth, 32, 1, false, 0, Math.PI)
}

const CIRCLE = {
	geometry: new CylinderGeometry(BOX.height / 2, BOX.height / 2, BOX.depth, 64),
}

const AVATAR = {
	url: ""
}

const TUBE = {
	unionLength: {
		boxMode: 3.2,
		circleMode: 1
	},
	descendantLength: {
		boxMode: 1.6,
		circleMode: 2.4
	},
	childrenLength: 10,
	childLength: 0.4,
	diameter: 0.02,
	gap: 0.3,
	unionGeo: {},
	descendantGeo: {},
	childrenGeo: {},
	childGeo: {},
	material: new MeshStandardMaterial({ color: COLORS.tube }),
	horizontal: 'horizontal',
	vertical: 'vertical'
}
TUBE.unionGeo.boxMode = new CylinderGeometry(TUBE.diameter, TUBE.diameter, TUBE.unionLength.boxMode, 15)
TUBE.descendantGeo.boxMode = new CylinderGeometry(TUBE.diameter, TUBE.diameter, TUBE.descendantLength.boxMode, 15)
TUBE.unionGeo.circleMode = new CylinderGeometry(TUBE.diameter, TUBE.diameter, TUBE.unionLength.circleMode, 15)
TUBE.descendantGeo.circleMode = new CylinderGeometry(TUBE.diameter, TUBE.diameter, TUBE.descendantLength.circleMode, 15)
TUBE.childrenGeo = new CylinderGeometry(TUBE.diameter, TUBE.diameter, TUBE.childrenLength, 15)
TUBE.childGeo = new CylinderGeometry(TUBE.diameter, TUBE.diameter, TUBE.childLength, 15)

const ANIM = {
	transition: 0.4,
	ease: "power3.out"
}

const WINDOW = {
	horizPadding: {
		boxMode: 1.5,
		circleMode: 0.6
	},
	vertPadding: {
		boxMode: 1.5,
		circleMode: 2.1
	}
}

export { MODE, COLORS, BOX, BORDER, CIRCLE, TUBE, ANIM, AVATAR, WINDOW }
