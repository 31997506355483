import Singleton from './Singleton'

export default class Boxes extends Singleton {
	constructor() {
		super(Boxes)
		if (!this.items) {
			this.items = []
		}
	}

	empty() {
		this.items = []
	}
}
