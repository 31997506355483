import People from "./People";
import Singleton from "./Singleton";

export default class CollisionChecker extends Singleton {
	constructor() {
		super(CollisionChecker)
	}

	checkCollision(currentPerson) {
		for (const p1 of new People().items) {
			for (const p2 of new People().items) {
				if (p1.isAscendant && p1 !== p2 && p1.boundingBox.intersectsBox(p2.boundingBox)) {
					const personToMove = p1.descendantPersonToMove[0]
					personToMove.ascendantMove()
					this.checkCollision()
					return
				} else if (this.p1isAtTheRightOfHisBranch(p1, p2)) {
					if (this.collisionBetweenMotherAndSiblingOfHusband(p1, p2)) {
						p1.spouses[0].descendantMove()
						this.checkCollision(currentPerson)
						return
					} else if (this.collisionBetweenSiblingsWithDifferentMother(p1, p2)) {
						p2.doubleSpouseMove()
						this.checkCollision(currentPerson)
					} else {
						let buddy
						if (p1.siblings.length) {
							buddy = p1
						} else {
							buddy = p1.spouses[0]
						}
						while (!buddy.parents.includes(currentPerson)) {
							buddy.father.descendantMove()
							buddy = buddy.father
						}
						this.checkCollision(currentPerson)
						return
					}
				}
			}
		}
	}

	p1isAtTheRightOfHisBranch(p1, p2) {
		return p1.isDescendant &&
			p1.bound === 'right' &&
			p1 !== p2 &&
			p1.boundingBox.intersectsBox(p2.boundingBox) &&
			((p1.bound === 'right' && p2.bound === 'left') ||
				(p1.bound === 'right' && p2.bound === 'right' && ((p1.spouses[0] && !p2.spouses[0]) || 
																													p2.siblings.length === 1)))
	}

	collisionBetweenMotherAndSiblingOfHusband(p1, p2) {
		return p1.spouses[0] && p2.siblings.includes(p1.spouses[0])
	}

	collisionBetweenSiblingsWithDifferentMother(p1, p2) {
		return p1.father === p2.father && p1.mother !== p2.mother
	}
}
