import Singleton from "./Singleton";

export default class People extends Singleton {
	constructor() {
		super(People)
		if (!this.items) {
			this.items = []
		}
	}

	add(person) {
		this.items.push(person)
	}

	empty() {
		this.items = []
	}
}
